<template>
    <div class="text-slate-800 grid gap-7.5">
        <div>
            <div class="text-xl font-bold flex items-center gap-1.5 mb-1.5">
                <UIIcon name="front/heroicons-solid/map-pin" class="w-5 h-5"/>
                <span>Address:</span>
            </div>

            <address class="not-italic text-lg">515 Hamilton St, Costa Mesa, CA 92627</address>
        </div>

        <div>
            <div class="text-xl font-bold flex items-center gap-1.5 mb-1.5">
                <UIIcon name="front/heroicons-solid/phone" class="w-5 h-5"/>
                <span>Contact Number:</span>
            </div>

            <ULink class="font-medium" to="tel:+1 (123) 456-7890">+1 (123) 456-7890.</ULink>
        </div>

        <div>
            <div class="text-xl font-bold flex items-center gap-1.5 mb-1.5">
                <UIIcon name="front/heroicons-solid/clock" class="w-5 h-5"/>
                <span>Business Hours:</span>
            </div>

            <div class="font-medium">Mon - Fri 8:00 AM - 6:00 PM<br>Sat 8:00 AM - 5:00 PM</div>
        </div>

    </div>
</template>
<script setup lang="ts">
</script>
